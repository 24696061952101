import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { isDev, getJSONData } from '../../utils';
import axios from 'axios';
import $ from 'jquery';
import Select, { components } from 'react-select';
import UIBlocker from '../UIBlocker';
import { strings } from '../../localization/localization';

const bulkActionsMount = document.getElementById('bulk_actions_mount');

const fakeJSONData = {
  context: 'social_profiles'
};

const defaultJsonData = {
  context: null,
  actions: []
};

const checkboxSelector = 'input[type=checkbox]';
const getCheckedIds = () => (
  $('.data-table').find(checkboxSelector + '[name="bulk_actions_select[]"]:checked')
    .toArray()
    .map(cb => cb.value)
);

const attachBulkCheckboxHandlers = () => {
  let lastChecked = null;
  const $dataTable = $('.data-table');
  const checkboxSelector = 'input[type=checkbox]';
  $dataTable.on('click', checkboxSelector, function(e) {
    const checkbox = $(this);
    const all = checkbox.attr('name') === 'bulk_actions_select_all';

    if (all) {
      $dataTable.find(checkboxSelector).prop('checked', this.checked);
      window.setBulkActionIds(getCheckedIds());
      return;
    }

    if (!lastChecked) {
      lastChecked = this;
      window.setBulkActionIds(getCheckedIds());
      return;
    }

    if (e.shiftKey && lastChecked) {
      var from = $dataTable.find(checkboxSelector).index(this);
      var to = $dataTable.find(checkboxSelector).index(lastChecked);

      var start = Math.min(from, to);
      var end = Math.max(from, to) + 1;

      $dataTable.find(checkboxSelector).slice(start, end)
        .prop('checked', lastChecked.checked);
    }

    lastChecked = this;

    window.setBulkActionIds(getCheckedIds());
  });
}

function BulkActions(props) {
  const [ready, setReady] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [context, setContext] = useState('');
  const [actions, setActions] = useState([]);
  const [selectedAction, setSelectedAction] = useState(null);
  const [ids, setIds] = useState([]);

  useEffect(() => {
    if (!!bulkActionsMount) {
      initialize();
    }
  }, []);

  const initialize = () => {
    const jsonData = { ...defaultJsonData, ...getJSONData('#bulk_actions_mount', isDev() ? fakeJSONData : null) };

    setContext(jsonData.context);

    window.bulkActions = { selectedIds: [] };
    window.getBulkActionIds = () => window.bulkActions.selectedIds;
    window.setBulkActionIds = ids => {
      setIds([...ids]);
      window.bulkActions.selectedIds = [...ids];
    };

    const supportedActions = jsonData.bulk_actions || [];
    const formData = jsonData.form_data || {};

    // Load server data
    axios.get(jsonData.ajax_url, {
      params: {
        bulk_actions: supportedActions,
        form_data: formData,
      },
      crossDomain: true
    })
    .then(function (response) {
      // handle success
      setActions(response.data.available_actions);
      setReady(true);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
    });

    attachBulkCheckboxHandlers();
  }

  const confirmSelection = (selectedAction, ids) => {
    if (ids.length) {
      return window.confirm(strings.formatString(strings.BulkActions.confirmApplyBulkAction, { bulkAction: selectedAction._bulkAction.name }));
    } else {
      return window.confirm(strings.formatString(strings.BulkActions.confirmNoRecordsSelected, { bulkAction: selectedAction._bulkAction.name }));
    }
  }

  const apply = (selectedAction, ids, e) => {
    e.preventDefault();
    if (!selectedAction) return;
    if (selectedAction.disabled) {
      setSelectedAction(null);
      return;
    }

    if (!confirmSelection(selectedAction, ids)) return;

    if (isDev()) {
      console.log('Submitted');
    } else {
      setSubmitting(true);
      $('#bulk_actions').submit();
    }
  }

  if (!ready) return null;

  const component = (
    <div className="ui-block-container">
      <Select
        isMulti={false}
        options={actions.map(a => ({
          label: a.name,
          value: a.slug,
          _bulkAction: a,
        }))}
        onChange={selectedOption => {
          setSelectedAction(selectedOption);
        }}
        name="action"
        value={selectedAction}
        className='raleway-semi-bold raleway-12 react-select-container'
        components={{
          Option: ({ innerProps, data, isDisabled, ...restProps }) => {
            const customInnerProps = { ...innerProps };
            if (data._bulkAction.disabled && data._bulkAction.disabled_reason) {
              const disabledMsg = `${strings.BulkActions.disabled}: ${data._bulkAction.disabled_reason}`;
              customInnerProps.onClick = () => {
                alert(disabledMsg);
              };
              customInnerProps.title = disabledMsg;
            }

            return (
              <components.Option
                innerProps={customInnerProps}
                data={data}
                isDisabled={data._bulkAction.disabled} {...restProps}
              />
            );
          }
        }}
      />
      <button
        onClick={e => apply(selectedAction, ids, e)}
        className="btn"
      >
        {strings.BulkActions.apply}
      </button>
      <input key="context" type="hidden" name="context" value={context} />
      {ids.map((id, i) => (
        <input key={id} type="hidden" name={`ids[${i}]`} value={id} />
      ))}
      <UIBlocker block={submitting} />
    </div>
  );

  if (!bulkActionsMount) {
    return null;
  }

  return ReactDOM.createPortal(
    component,
    bulkActionsMount,
  );
}

export default BulkActions;
